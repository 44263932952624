import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AirportContext from 'contexts/Airports';
import config from 'config/default.js';
const { ERROR_TIMEOUT } = config;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100px',
    zIndex: 9999,
    position: 'absolute',
    left: '50%',
    bottom: '30px',
    transform: 'translateX(-50%)',
    maxWidth: '940px',
    width: '90%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Alerter = () => {
  const classes = useStyles();
  const {
    showAlert, setShowAlert,
    alertMessage,
    alertSeverity,
  } = useContext(AirportContext);

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, ERROR_TIMEOUT * 1000);
      return () => clearTimeout(timeout);
    }
  }, [showAlert, setShowAlert]);

  useEffect(() => {
    if (alertMessage && alertMessage.length > 0) {
      setShowAlert(true);
    }
  }, [alertMessage, setShowAlert]);

  return (
    <>
    { showAlert ?
    <div className={classes.root}>
      <Alert severity={alertSeverity} onClose={() => setShowAlert(false)}>{alertMessage}</Alert>
    </div>
    : ('') }
    </>
  )
}

export default Alerter;