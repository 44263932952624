import React, { useState, useContext, useCallback } from 'react';
import AirportContext from 'contexts/Airports';
import axios from 'axios';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { useParams } from "react-router-dom";
import config from 'config/default.js';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Cancel } from '@material-ui/icons';

import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const {
  SAVE_TIMEOUT,
  MAX_POINTS,
  API_URL,
} = config;

const Save = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('Password');
  const { id } = useParams();
  const {
    depart,
    arrive,
    points,
    setAlertMessage,
  } = useContext(AirportContext);
  const [canSave, setCanSave] = useState<boolean>(true);

  const cancel = useCallback(() => { setOpen(false); }, []);

  const savePoints = useCallback(async () => {
    if (!canSave) {
      return setAlertMessage(`You can only save once every ${SAVE_TIMEOUT} seconds.`);
    }

    const route = points.slice(1, points.length-1) // remove depart/arrive
      .slice(0, MAX_POINTS); // ensure less than max

    const payload = {
      depart: depart.icao,
      arrive: arrive.icao,
      route,
      password,
    }

    await axios.post(`${API_URL}${id}`, payload, { responseType: 'json' })
      .catch(e => {
        setAlertMessage(`${e}`);
      });

    setCanSave(false);
    setTimeout(() => {
      setCanSave(true);
    }, SAVE_TIMEOUT * 1000);
    cancel();
  }, [canSave, arrive, cancel, depart, id, password, points, setAlertMessage]);

  const checkSave = useCallback(() => {
    if (
      !depart?.icao || 
      !arrive?.icao || 
      points.length === 0
    ) {
      return setAlertMessage(`You must have a valid route.`);
    }

    setOpen(true);
  }, [arrive, depart, points.length, setAlertMessage]);

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => checkSave()}>
        <CloudDownload />
      </Button>
      <Dialog aria-labelledby="save-dialog" open={open}>
        <DialogTitle id="save-dialog">Save Flight Plan</DialogTitle>
        <DialogContent>
          <DialogContent>
            <PasswordField onChange={(p: string) => setPassword(p)} onSubmit={savePoints} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={savePoints}>Save</Button>
            <IconButton onClick={cancel}>
              <Cancel />
            </IconButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default Save;



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  }),
);

interface State {
  password: string;
  showPassword: boolean;
}

const PasswordField = (props: any) => {
  const { onChange, onSubmit } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    if (onChange) onChange(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const checkSubmit = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      if (onSubmit) onSubmit();
    }
  }

  return (
    <div className={classes.root}>
      <div>
        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            onKeyDown={checkSubmit}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </div>
    </div>
  );
}
