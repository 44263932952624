import React, { useContext } from 'react';
import AirportContext from 'contexts/Airports';
import Search from 'components/Search';
import Export from 'components/Controller/Export';
import Clear from 'components/Controller/Clear';
import Save from 'components/Controller/Save';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const Controller = () => {
  const { showMap } = useContext(AirportContext);

  return (
    <>
    { showMap ?
    <div style={{ position: 'absolute', top: '10px', left: '60px' }}>
      <Card variant="outlined" style={{ background: 'rgba(245, 245, 255, 0.8)' }}>
        <CardContent>
          <Search
            label="Airports"
            style={{
              maxWidth: '100%',
            }}
          />
        </CardContent>
        <CardActions>
          <Clear />
          <Export />
          <Save />
        </CardActions>
      </Card>
    </div>
    : ('')
    }
    </>
  )
};

export default Controller;