// @flow

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Map, TileLayer, Viewport } from 'react-leaflet';
import { LatLngTuple, LeafletMouseEvent } from 'leaflet';
import Controller from 'components/Controller';
import Waypoint, { Point } from 'components/Waypoint';
import Route from 'components/Route';
import tinykeys from 'tinykeys';
import AirportContext, { Airport } from 'contexts/Airports';
import Airports from 'components/Airports';

import config from 'config/default.js';
const {
  MAX_CLICKS,
} = config;

type MapProps = {
  airport?: Airport;
}

const Mapper = ({ airport }: MapProps) => {
  // default zoom level to start
  const ZOOM = 13;
  const {
    points, setPoints,
    setAlertMessage,
    depart, setDepart,
    setArrive,
    showMap,
    center, setCenter,
    setViewCenter,
    addPoint,
  } = useContext(AirportContext);

  // zoom level state
  const [zoom, setZoom] = useState<number>(ZOOM);
  const [counter, setCounter] = useState<number>(0);
  const [waypoints, setWaypoints] = useState<Point[]>(points);

  /**
   * Undo
   * Removes the last point in the stack
   */
  useEffect(() => {
    let unsubscribe = tinykeys(window, {
      "$mod+KeyZ": event => {
        event.preventDefault();
        if (points.length > 0) {
          setArrive(undefined);
          const p = points.slice(0, points.length-1);
          setPoints([...p]);
          if (p.length === 0) {
            setDepart(undefined);
          }
        }
      }
    })
    return () => {
      unsubscribe()
    }
  });

  useEffect(() => {
    setWaypoints(points);
  }, [points]);

  /**
   * Reset counter if depart was removed
   */
  useEffect(() => {
    if (!depart) setCounter(0);
  }, [depart]);

  useEffect(() => {
    if (showMap && airport) {
      const position: LatLngTuple = [
        airport.lat,
        airport.lng,
      ];

      setCenter(position);
      setViewCenter(position);
      setPoints([{ id: airport.icao, point: position }]);
      setDeparture(airport);
    }
  }, [showMap, airport]); // eslint-disable-line

  const setDeparture = useCallback((airport: Airport) => {
    const position: LatLngTuple = [
      airport.lat,
      airport.lng,
    ];

    const formattedAirport = {
      name: airport.name,
      icao: airport.icao,
      altitude: airport.alt,
      position,
    };

    setDepart(formattedAirport);
    addPoint(position);
  }, [addPoint, setDepart]);

  const handleClick = useCallback((e: LeafletMouseEvent) => {
    if (!depart) {
      if (counter < MAX_CLICKS && points.length === 0) return setCounter(counter+1);
      return setAlertMessage('Select a departure airport first.');
    }

    const ll = e.latlng;
    const pt: LatLngTuple = [ll.lat, ll.lng];
    addPoint(pt);
  }, [depart, counter, addPoint, setAlertMessage, points.length]);

  const updateView = useCallback((view: Viewport) => {
    if (view && view.center && view.zoom) {
      setViewCenter(view.center);
      setZoom(view.zoom);
    }
  }, [setViewCenter]);

  return (
    <>
      <Map
        className="map"
        center={center}
        zoom={ZOOM}
        onClick={handleClick}
        onViewportChanged={updateView}
        style={{ zIndex: 0 }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        { showMap ? <>
          <Airports zoom={zoom} />
          <Route path={waypoints} />
          { waypoints.map((point: Point, i: number) =>
            <Waypoint
              key={point.id}
              point={point}
            />
          ) }
        </> : ('') }
      </Map>
      <Controller />
    </>
  )
}

export default Mapper;