import React, { useState, useEffect, useCallback } from 'react';
import airports from 'data/airports.json';
import { LatLngTuple } from 'leaflet';
import { Point } from 'components/Waypoint';
import { v4 as uuid } from 'uuid';
import {
  useParams,
} from 'react-router-dom';
import axios from 'axios';

import config from 'config/default.js';
const {
  MAX_POINTS,
  API_URL,
} = config;

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

export type ICAO = {
  icao: string;
  position: LatLngTuple;
  altitude: number;
  name: string;
};

export type Airport = { 
  id: number;
  name: string;
  city: string;
  country: string;
  iata: string;
  icao: string;
  lat: number;
  lng: number;
  alt: number;
  dst: string;
  gmt: number;
  timezone: string;
  type: string;
  source: string;
};

const AirportContext = React.createContext<any>({});
export default AirportContext;

export const GetAirport = (id: number): Airport => {
  const found = airports.filter(airport => airport.id === id);
  return found[0];
}

export const GetAirportByICAO = (icao: string): Airport => {
  const found = airports.filter(airport => airport.icao === icao);
  return found[0];
}

export const GetRandomAirport = () => {
  const atRandom = Math.round(Math.random() * airports.length);
  const randomAirport = airports[atRandom];
  if (randomAirport.lat === 0 && randomAirport.lng === 0) {
    return atRandom === 0 ? airports[1] : airports[atRandom-1];
  }
  return randomAirport;
}

/**
 * Airport Provider
 * @param props React props
 */
export const AirportProvider = (props: any) => {
  const { id } = useParams();
  const randomAirport = GetRandomAirport();
  // Alert message
  const [alertMessage, setAlertMessage] = useState<string>('');
  // Alert severity (default Error)
  const [alertSeverity, setAlertSeverity] = useState<Severity>('error');
  // Alert visibility
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [depart, setDepart] = useState<ICAO>();
  const [arrive, setArrive] = useState<ICAO>();
  // Stack of path points
  const [points, setPoints] = useState<Point[]>([]);
  const [showMap, setShowMap] = useState<boolean>(false);
  // center point state
  const [center, setCenter] = useState<LatLngTuple>([randomAirport.lat, randomAirport.lng]);
  const [viewCenter, setViewCenter] = useState<LatLngTuple>(center);

  /**
   * Load existing route
   */
  useEffect(() => {
    axios.get(`${API_URL}${id}`, {
      responseType: 'json',
    }).then(response => {
      const { data, status } = response;
      if (status === 200 &&
        data &&
        data.route?.length > 0 &&
        data.depart &&
        data.arrive
      ) {
        const departData = GetAirportByICAO(data.depart);
        const arriveData = GetAirportByICAO(data.arrive);

        if (departData && arriveData) {
          setPoints([
            { id: uuid(), point: [departData.lat, departData.lng] },
            ...data.route, 
            { id: uuid(), point: [arriveData.lat, arriveData.lng] },
          ]);
          setDepart({
            icao: departData.icao,
            position: [departData.lat, departData.lng],
            name: departData.name,
            altitude: departData.alt,
          });
          setArrive({
            icao: arriveData.icao,
            position: [arriveData.lat, arriveData.lng],
            name: arriveData.name,
            altitude: arriveData.alt,
          });
          setCenter([departData.lat, departData.lng]);
          setViewCenter([departData.lat, departData.lng]);
          setShowMap(true);
        }
      }
    }).catch(err => {
      setAlertMessage(`${err}`);
    });

  }, [id]);

  const insertPoint = useCallback((point: LatLngTuple, id: string = 'WP') => {
    if (points.length > (MAX_POINTS-1)) {
      return setAlertMessage(`You are limited to ${MAX_POINTS} points.`);
    }

    const newPoint: Point = { id: `${id}${points.length}`, point };
    setPoints([newPoint, ...points]);
  }, [points]);

  const addPoint = useCallback((point: LatLngTuple, id: string = 'WP') => {
    if (points.length > (MAX_POINTS-1)) {
      return setAlertMessage(`You are limited to ${MAX_POINTS} points.`);
    }

    const newPoint: Point = { id: `${id}${points.length}`, point };
    setPoints([...points, newPoint]);
  }, [points]);

  if (!id) {
    return (
      <div>{props.children}</div>
    );
  }

  return (
    <AirportContext.Provider value={{
      airports,
      alertMessage, setAlertMessage,
      alertSeverity, setAlertSeverity,
      depart, setDepart,
      arrive, setArrive,
      points, setPoints,
      showAlert, setShowAlert,
      showMap, setShowMap,
      center, setCenter,
      viewCenter, setViewCenter,
      addPoint, insertPoint,
    }}>
      {props.children}
    </AirportContext.Provider>
  );
};