import React, { useContext, useCallback } from 'react';
import Automcomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Airport } from 'contexts/Airports';
import AirportContext from 'contexts/Airports';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (airport: Airport) => `${airport.name}, ${airport.city} - ${airport.icao}`,
  limit: 10,
});

type SearchProps = {
  style?: any;
  label?: string;
  size?: "small" | "medium";
}

const Search = ({ style, label, size }: SearchProps) => {
  const { airports, setCenter, setViewCenter } = useContext(AirportContext);
  const _size = size || 'small';

  const onSearch = useCallback((airport?: Airport | null) => {
    if (airport) {
      setCenter([airport.lat, airport.lng]);
      setViewCenter([airport.lat, airport.lng]);
    }
  }, [setCenter, setViewCenter]);

  return (
    <Automcomplete
      id="airport-autocomplete"
      options={airports}
      filterOptions={filterOptions}
      getOptionLabel={(option: Airport) => `${option.name}, ${option.city} - ${option.icao}`}
      renderInput={(params) => 
        <TextField {...params} 
          label={label}
          size={_size}
          variant="outlined"
          color="primary"
          style={style}
        />
      }
      onChange={(_e, airport: Airport | null, _r) => onSearch(airport)}
    />
  )
};

export default Search;