import { LatLngTuple } from 'leaflet';

export const DMS = (point: LatLngTuple) => {
  const [lat,lng] = point;
  const tmplat = lat.toString().split('.');
  const tmplng = lng.toString().split('.');
  const latd = parseInt(tmplat[0]);
  const lngd = parseInt(tmplng[0]);

  const tmpmlat = parseFloat('0.' + tmplat[1]) * 3600;
  const latm = Math.floor(tmpmlat / 60);
  const lats = tmpmlat - (latm * 60);

  const tmpmlng = parseFloat('0.' + tmplng[1]) * 3600;
  const lngm = Math.floor(tmpmlng / 60);
  const lngs = tmpmlng - (lngm * 60);

  const fmt = {
    lat: {
      d: latd,
      m: latm,
      s: lats,
    },
    lng: {
      d: lngd,
      m: lngm,
      s: lngs,
    }
  };

  let latitude = '';
  let longitude = '';

  if (fmt.lat.d > 0) {
    latitude = `N${latd}° ${latm}' ${lats.toFixed(4)}"`;
  } else {
    latitude = `S${-latd}° ${latm}' ${lats.toFixed(4)}"`;
  }

  if (fmt.lng.d > 0) {
    longitude = `E${lngd}° ${lngm}' ${lngs.toFixed(4)}"`;
  } else {
    longitude = `W${-lngd}° ${lngm}' ${lngs.toFixed(4)}"`;
  }

  return `${latitude},${longitude}`;
}

export const FmtAlt = (altitude: number) => {
  return ( 
    (altitude < 100000 ? '0' : '') +
    (altitude < 10000 ? '0' : '') +
    (altitude < 1000 ? '0' : '') +
    (altitude < 100 ? '0' : '') +
    (altitude < 10 ? '0' : '') +
    parseInt(altitude.toString())
  );
};