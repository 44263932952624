import React, { useState, useContext, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import AirportContext from 'contexts/Airports';
import Card from '@material-ui/core/Card';

import { 
  Cancel,
  DeleteForever,
} from '@material-ui/icons';

import IconButton from '@material-ui/core/IconButton';
import Popup from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const Clear = () => {
  const {
    setDepart, 
    setArrive,
    setPoints,
  } = useContext(AirportContext);
  const [checkClear, setCheckClear] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const clearMap = () => {
    setPoints([]);
    setDepart(undefined);
    setArrive(undefined);
  };

  const verifyClear = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setCheckClear(true);
  }, [setCheckClear]);

  const cancel = useCallback(() => {
    setCheckClear(false);
    setAnchorEl(null);
  }, []);

  const id = checkClear ? 'clear-popover' : undefined;

  return (
    <>
      <IconButton color="secondary" onClick={verifyClear} aria-describedby={id}>
        <DeleteForever />
      </IconButton>
      <Popup
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={checkClear}
        onBlur={() => setCheckClear(false)}
      >
        <Card variant="outlined" style={{ padding: '1rem' }}>
          <Typography>Are you sure?</Typography>
          <Button variant="contained" color="secondary" onClick={() => { clearMap(); setCheckClear(false) }}>Yes</Button>
          <IconButton onClick={cancel}>
            <Cancel />
          </IconButton>
        </Card>
      </Popup>
    </>
  )
};

export default Clear;