import React, { useEffect, useState, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';
import L, { LatLngTuple } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import LocalAirport from '@material-ui/icons/LocalAirport';
import Button from '@material-ui/core/Button';
import AirportContext, { Airport } from 'contexts/Airports';
import styled from 'styled-components';

const Details = styled.address`
  font-style: normal;
  padding: 1rem 0;
  & h3,
  & h2 {
    margin: 0;
  }
`;

const Icon = L.divIcon({
  className: 'airport-icon',
  iconSize: [35, 35],
  iconAnchor: [17.5, 17.5],
  popupAnchor: [0, -17.5],
  html: ReactDOMServer.renderToString(<LocalAirport color="primary" fontSize="large" />)
});

type AirportsProps = {
  zoom: number;
}

const Airports = ({ zoom }: AirportsProps) => {
  const {
    airports,
    depart,
    setDepart,
    setArrive,
    addPoint, insertPoint,
    viewCenter,
  } = React.useContext(AirportContext);

  const [center, setCenter] = useState(viewCenter);

  useEffect(() => {
    setCenter(viewCenter);
  }, [viewCenter]);

  const getAirportsInView = useCallback((center: LatLngTuple, zoom: number): Airport[] => {
    let inView: Airport[] = [];
    let diff = 0.5;
    let factor = 2;
  
    switch (zoom) {
      case 18:
      case 17:
      case 16:
      case 15:
      case 14:
      case 13: diff = 0.3; break;
      case 12: diff = 0.6; break;
      case 11: diff = 0.8; break;
      case 10: diff = 1; break;
      case 9: diff = 2; break;
      case 8: diff = 3; factor = 6; break;
      case 7: diff = 4; factor = 6; break;
      case 6: diff = 10; factor = 6; break;
      case 5: diff = 15; factor = 6; break;
      case 4: diff = 30; factor = 10; break;
      case 3: diff = 500; factor = 20; break;
      case 2: diff = 800; factor = 30; break;
      case 1: diff = 1000; factor = 40; break;
      default: diff = 0.5;
    }

    for (let i = 0; i < airports.length; i++) {
      const ap = airports[i];

      if (zoom > 7 || 
        (Math.round(ap.lat * 10000) % factor === 0 && Math.round(ap.lng * 10000) % factor === 0)
      ) {
        if (Math.abs(ap.lat - center[0]) < diff && 
          Math.abs(ap.lng - center[1]) < (diff*2.2)) {
          inView.push(ap);
        }
      }
    }
  

    return inView;
  }, [airports]);

  const setAirport = useCallback((value: string, airport: Airport) => {
    const pos: LatLngTuple = [
      airport.lat,
      airport.lng,
    ];

    const dest = {
      icao: airport.icao,
      altitude: airport.alt,
      position: pos,
      name: airport.name,
    };

    if (value === 'depart') {
      setDepart(dest);
      insertPoint(dest.position);
    }
    if (value === 'arrive') {
      setArrive(dest);
      addPoint(dest.position);
    }
  }, [addPoint, setArrive, setDepart, insertPoint]);

  const airportsInView = getAirportsInView(center, zoom);

  return (
    <>
      { airportsInView.map((airport, i) => (
        <Marker key={airport.icao} position={[airport.lat, airport.lng]} icon={Icon}>
          <Popup>
            <Details>
              <h2>{ airport.icao }</h2>
              <h3>{ airport.name }</h3>
              { airport.city }, { airport.country }
            </Details>
            { depart ?
              <Button onClick={() => setAirport('arrive', airport)}>
                Arrive
              </Button>
            :
              <Button onClick={() => setAirport('depart', airport)}>
                Depart
              </Button>
            }
          </Popup>
        </Marker>
      )) }
    </>
  );
};

export default Airports;
