import React, { useContext, useState, useCallback } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Adjust from '@material-ui/icons/Adjust';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteForever from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import AirportContext from 'contexts/Airports';
import styled from 'styled-components';

const MarkerIcon = styled.div`
  & .title {
    opacity: 0;
    animate: opacity 0.3s;
  }

  &:hover .title {
    opacity: 1;
  }
`;

const Title = styled.div`
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
`;

export type Point = {
  id: string;
  point: LatLngTuple;
}

type DestinationProps = {
  point: Point;
}

const Waypoint = ({ point }: DestinationProps) => {
  const {
    points, setPoints, addPoint,
    depart, setDepart, 
    setArrive,
  } = useContext(AirportContext);

  const [lat, setLat] = useState<number>(point.point[0]);
  const [lng, setLng] = useState<number>(point.point[1]);
  const [pointId, setPointId] = useState<string>(point.id);

  const removePoint = useCallback((id: string) => {
    const newPoints = points.filter((p: Point, i: number) => {
      if (i === 0 && p.id === id) setDepart(undefined);
      if (i === points.length-1 && p.id === id) setArrive(undefined);
      console.log(p.id, '  ', id);
      return p.id !== id;
    });
    setPoints(newPoints);
  }, [points, setArrive, setDepart, setPoints]);

  const updateHandler = useCallback((e: any) => {
    const lat = e.target._latlng.lat;
    const lng = e.target._latlng.lng;

    setLat(lat);
    setLng(lng);

    const newPoints = points.map((p: Point, i: number) => {
      if (p.id === point.id) {
        p.point = [lat, lng] as LatLngTuple;

        if (i === 0) setDepart(undefined);
        if (i === points.length-1) setArrive(undefined);
      }
      return p;
    });

    setPoints(newPoints);
  }, [point.id, points, setPoints, setDepart, setArrive]);

  const changeArrival = useCallback(() => {
    setArrive(depart);
    addPoint(depart.position);
  }, [depart, addPoint, setArrive]);

  const Icon = L.divIcon({
    className: 'waypoint-icon',
    iconSize: [35, 35],
    iconAnchor: [17.5, 17.5],
    popupAnchor: [0, -17.5],
    html: ReactDOMServer.renderToString(
      <MarkerIcon>
        <Title className='title'>{`[${lat}, ${lng}]`}</Title>
        <Adjust color="secondary" fontSize="large" />
      </MarkerIcon>
    )
  });

  const updatePointId = useCallback((e: any) => {
    const newId = e.target.value;
    setPointId(newId);
  }, []);

  const handleClose = useCallback(() => {
    const newPoints = points.map((p: Point, i: number) => {
      if (p.id === point.id) {
        p.id = pointId;
      }
      return p;
    });

    setPoints(newPoints);
  }, [point.id, pointId, setPoints, points]);

  return (
    <Marker position={[lat, lng]} draggable={true} icon={Icon} ondragend={updateHandler}>
      <Popup onClose={handleClose}>
        <TextField value={pointId} onChange={updatePointId} style={{ minWidth: '200px' }} /><br />
        { (points[0] === point) ? <Button color="primary" onClick={changeArrival}>Arrive</Button> : ('') }
        <IconButton onClick={() => removePoint(pointId)} color="secondary"><DeleteForever /></IconButton>
      </Popup>
    </Marker>
  );
}

export default Waypoint;