import React from 'react';
import { Polyline } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { Point } from '../Waypoint';
import pink from '@material-ui/core/colors/pink';

type RouteProps = {
  path: Point[];
}

const Route = ({ path }: RouteProps) => {
  const _points: LatLngTuple[] = [];
  path.map((p,i) => _points[i] = p.point);
  return ( <Polyline color={pink[400]} positions={_points} /> );
};

export default Route;