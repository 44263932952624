import React from 'react';
import './App.css';
import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import NewRoute from 'routes/NewRoute';
import Home from 'routes/Home';
import { AirportProvider } from 'contexts/Airports';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500], // #2090ff??
    },
    secondary: {
      main: pink[500],
    },
  },
  typography: {
    subtitle1: {
      fontFamily: [
        'Vollkorn',
      ].join(','),
      fontSize: 40,
    },
  }
});

const Container = styled.div`
  .map {
    height: 100vh;
  }
`;

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/r/:id">
              <Container>
                <AirportProvider>
                  <Home />
                </AirportProvider>
              </Container>
            </Route>
            <Route path="/">
              <NewRoute />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
