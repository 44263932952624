import React from 'react';
import Map from 'components/Map';
import Splash from 'components/Splash';
import Alert from 'components/Alert';

const Home = () => {
  return (
    <>
      <Map />
      <Splash />
      <Alert />
    </>
  );
}

export default Home;
