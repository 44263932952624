import React, { useState, useContext, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import AirportContext from 'contexts/Airports';
import { SaveAlt, Cancel } from '@material-ui/icons';
import { Point } from 'components/Waypoint';
import { FmtAlt, DMS } from 'components/helpers';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

const Waypoints = (points: Point[], altitude?: number) => {
  let o = '';
  const alt = altitude ? FmtAlt(altitude) : '001000';
  
  // exclude first and last
  for (let i = 1; i < points.length-1; i++) {
    o += `
        <ATCWaypoint id="${points[i].id}">
            <ATCWaypointType>User</ATCWaypointType>
            <WorldPosition>${DMS(points[i].point)},+${alt}.00</WorldPosition>
        </ATCWaypoint>`;
  }

  return o;
}

const ExportDialog = (props: any) => {
  const { onClose, open } = props;
  const [filename, setFilename] = useState<string>('ROUTE');

  const cancel = useCallback(() => { onClose(); }, [onClose]);

  const handleClose = useCallback(() => { onClose(filename); }, [filename, onClose]);

  return (
    <Dialog onClose={cancel} aria-labelledby="export-dialog" open={open}>
      <DialogTitle id="export-dialog">Export Flight Plan</DialogTitle>
      <DialogContent>
        <DialogContent>
          <TextField value={filename} onChange={(n) => setFilename(n.currentTarget.value)} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>Download</Button>
          <IconButton onClick={cancel}>
            <Cancel />
          </IconButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}


const Export = () => {
  const { depart, arrive, points, setAlertMessage } = useContext(AirportContext);
  const [showExport, setShowExport] = useState<boolean>(false);
  const exportDialog = () => setShowExport(true);

  const handleExport = useCallback((filename?: string) => {
    setShowExport(false);
    if (!filename) return;

    if (!depart || !arrive) {
      return setAlertMessage('You need a departure and arrival airport to export.');
    }

    // TODO: find altitude along waypoints
    const cruisingAlt = ((depart.altitude > arrive.altitude)? depart.altitude : arrive.altitude) + 1000;

    const waypoints = Waypoints(points, cruisingAlt);

    const XML = `<?xml version="1.0" encoding="UTF-8"?>

<SimBase.Document Type="AceXML" version="1,0">
    <Descr>AceXML Document</Descr>
    <FlightPlan.FlightPlan>
        <Title>Let's Fly Together</Title>
        <FPType>VFR</FPType>
        <CruisingAlt>${cruisingAlt}.000</CruisingAlt>
        <DepartureID>${depart.icao}</DepartureID>
        <DepartureLLA>${DMS(depart.position)},+${FmtAlt(depart.altitude)}.00</DepartureLLA>
        <DestinationID>${arrive.icao}</DestinationID>
        <DestinationLLA>${DMS(arrive.position)},+${FmtAlt(arrive.altitude)}.00</DestinationLLA>
        <Descr>Flight Planner for Flight Simulator 2020</Descr>
        <DepartureName>${depart.name}</DepartureName>
        <DestinationName>${arrive.name}</DestinationName>
        <AppVersion>
            <AppVersionMajor>11</AppVersionMajor>
            <AppVersionBuild>282174</AppVersionBuild>
        </AppVersion>
        <ATCWaypoint id="${depart.icao.toUpperCase()}">
            <ATCWaypointType>Airport</ATCWaypointType>
            <WorldPosition>${DMS(depart.position)},+${FmtAlt(depart.altitude)}.00</WorldPosition>
            <ICAO>
                <ICAOIdent>${depart.icao.toUpperCase()}</ICAOIdent>
            </ICAO>
        </ATCWaypoint>${waypoints}
        <ATCWaypoint id="${arrive.icao.toUpperCase()}">
            <ATCWaypointType>Airport</ATCWaypointType>
            <WorldPosition>${DMS(arrive.position)},+${FmtAlt(arrive.altitude)}.00</WorldPosition>
            <ICAO>
                <ICAOIdent>${arrive.icao.toUpperCase()}</ICAOIdent>
            </ICAO>
        </ATCWaypoint>
    </FlightPlan.FlightPlan>
</SimBase.Document>
    `;

    const element = document.createElement('a');
    const file = new Blob([XML], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `${filename}.PLN`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }, [arrive, depart, points, setAlertMessage]);

  return (
    <>
      <Button variant="contained" color="primary" onClick={exportDialog}>
        <SaveAlt />
      </Button>
      <ExportDialog open={showExport} onClose={handleExport} />
    </>
  );
}

export default Export;