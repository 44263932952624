import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AirportContext from 'contexts/Airports';
import Search from 'components/Search';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchBox = styled.div`
  width: 300px;
  margin: 4rem 0;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(33, 150, 243, 0.7);
  height: 100vh;
  width: 100vw;
`;


const Splash = () => {
  const { showMap, setShowMap } = useContext(AirportContext);

  const onHide = () => {
    setShowMap(true);
  }

  return (
    <>
    { !showMap ?
    <>
      <Background />
      <Container>
        <Typography variant="subtitle1" component="h1" style={{ color: 'white', fontWeight: 'bold' }}>
          Let's Fly Together
        </Typography>
        <SearchBox>
          <Search
            label="Choose a departure airport"
            size="medium"
            style={{
              background: 'rgba(255,255,255,0.9)',
              borderRadius: '4px',
              border: '10px solid white',
              boxShadow: `15px 15px 30px #1666A8, -15px -15px 30px #2090D6`,
            }}
          />
        </SearchBox>
        <Button
          onClick={() => onHide()}
          variant="contained"
          color="secondary"
        >Explore the map</Button>
      </Container>
    </>
    : ('')
    }
    </>
  )
}

export default Splash;